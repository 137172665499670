import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Layout from '../components/layout'
import API_URL from '../utilities/APIUrl'
import SEO from '../components/seo'
import ValidationRules from '../utilities/ValidationRules'
import axios from 'axios'
import _ from 'lodash'

const Post = (props) => {
  const rules = ValidationRules()
  const theme = useTheme()
  const [thanksOpen, setThanksOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [isSaving, setIsSaving] = useState()
  const { featuredImage: post } = props.pageContext
  const { title } = props.pageContext
  const { meta } = props.pageContext
  const { categories } = props.pageContext

  const { handleSubmit, errors, control, reset } = useForm()

  const nameName = 'name'
  const emailName = 'email'
  const messageName = 'message'

  const onSubmit = (data) => {
    setIsSaving(true)
    return submitForm(data)
  }

  const submitForm = (data) => {
    let form = new FormData()
    form.append('your-name', data.name)
    form.append('your-email', data.email)
    form.append('sent-from-url', window.location.href)
    form.append('your-subject', 'Intresseanmälan')
    form.append('your-message', data.message)

    axios
      .post(
        `${API_URL}wp-json/contact-form-7/v1/contact-forms/1813/feedback`,
        form,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
          },
        }
      )
      .then((response) => {
        reset()
        setThanksOpen(true)
      })
      .catch((error) => {
        console.log('error: ', error)
        setErrorOpen(true)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const handleThanksClose = () => {
    setThanksOpen(false)
  }

  const handleErrorClose = () => {
    setErrorOpen(false)
  }

  return (
    <Layout>
      <SEO
        keywords={[`Karl-Åke`, `Nyström`, `Konst`, `Tavlor`, `Silver`]}
        title={props.pageContext.title}
      />
      <Dialog
        open={thanksOpen}
        onClose={handleThanksClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Tack!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vi har nu fått din intresseanmälan och kommer att återkomma så snart
            vi kan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleThanksClose} color="primary">
            Okej
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Ånej!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ett fel har uppstått när intresseanmälan skulle skickas. Om du vill
            kan du istället skicka ett mail direkt till
            <a href="mailto:alex@alexwebb.se">alex@alexwebb.se</a>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} color="primary">
            Okej
          </Button>
        </DialogActions>
      </Dialog>
      <section
        className="w-full mb-8"
        style={{
          backgroundColor: theme?.palette?.primary.dark,
          backgroundImage:
            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='" +
            theme?.palette.primary.light.split('#')[0] +
            "' fill-opacity='0.22' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E\")",
        }}
      >
        <div className="flex flex-row flex-wrap sm:flex-no-wrap md:items-stretch max-w-6xl mx-auto w-full">
          <div className="w-full sm:w-1/2 flex items-center justify-center">
            <img
              className="w-full h-auto shadow-md mx-4 my-4 md:my-8 sm:mx-0"
              srcSet={post.node.srcSet}
              alt={title}
            />
          </div>
          <div className="w-full sm:w-1/2 p-2 md:p-8 flex flex-col items-center">
            <div className="w-full rounded shadow bg-section-light flex-start p-4 mb-4 text-sm">
              <h1 className="text-lg font-sans-bold text-black mb-2">
                {title}
              </h1>

              <hr className="mb-4" />
              {meta.sald ? (
                <p>Detta konstverk är sålt till en lycklig köpare</p>
              ) : (
                <p>
                  <span className="font-sans-bold">Pris: </span>
                  <span>
                    {meta.matt ? meta.matt : 'Inget pris finns tillgängligt'}
                  </span>
                </p>
              )}
              <p>
                <span className="font-sans-bold">Mått: </span>
                <span>
                  {meta.matt ? meta.matt : 'Inga mått finns tillgängliga'}
                </span>
              </p>
              <p>
                <span className="font-sans-bold">Beskrivning: </span>
                <span>
                  {meta.beskrivning
                    ? meta.beskrivning
                    : 'Ingen beskrivning tillgänglig'}
                </span>
              </p>
              <p>
                <span className="font-sans-bold">Kategori: </span>
                <span>
                  {categories.length
                    ? categories.map((category) => category.name).join(', ')
                    : 'Tillhör ingen kategori'}
                </span>
              </p>

              <div className="w-full flex mt-12">
                <p className="text-xs font-sans-italic">
                  Om du saknar någon speciell information kan du höra av dig via
                  formuläret här nedan. Vi svarar oftast inom en eller två
                  arbetsdagar.
                </p>
              </div>
            </div>
            {!meta.sald ? (
              <div className="w-full rounded shadow bg-section-light flex-start p-4">
                <h2 className="text-lg text-black mb-2">Anmäl intresse</h2>
                <hr className="mb-4" />
                <form
                  className="add-users-form"
                  onSubmit={handleSubmit(onSubmit)}
                  encType="multipart/form-data"
                  noValidate
                >
                  <div className="mb-4">
                    <Controller
                      as={
                        <TextField
                          label={'Namn'}
                          variant="outlined"
                          className="w-full"
                          required
                          error={!!_.get(errors, nameName)}
                          helperText={_.get(errors, nameName)?.message}
                          disabled={isSaving}
                        />
                      }
                      name={nameName}
                      control={control}
                      rules={{ required: 'Obligatoriskt fält' }}
                      defaultValue={''}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      as={
                        <TextField
                          label={'Email'}
                          variant="outlined"
                          className="w-full"
                          required
                          error={!!_.get(errors, emailName)}
                          helperText={_.get(errors, emailName)?.message}
                          disabled={isSaving}
                        />
                      }
                      name={emailName}
                      control={control}
                      rules={rules.email}
                      defaultValue={''}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      as={
                        <TextField
                          label={'Meddelande'}
                          className="w-full"
                          variant="outlined"
                          error={!!_.get(errors, messageName)}
                          helperText={_.get(errors, messageName)?.message}
                          multiline
                          disabled={isSaving}
                        />
                      }
                      control={control}
                      name={messageName}
                      rules={{
                        required: 'Obligatoriskt fält',
                      }}
                      defaultValue={''}
                    />
                  </div>
                  <div className="w-full flex mt-6">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSaving}
                    >
                      Skicka
                    </Button>
                  </div>
                </form>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Post
